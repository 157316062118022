<template >
    <div v-if="initializing"> </div>
    <div v-if="!initializing"> 
        <button v-if="!dsPOPurchaseOrder.current.ID && !props.readOnly" class="btn btn-primary btn-sm" @click="newPOModalRef.show" style="widht:auto;"> {{ $t("Create PO") }} </button>

        <OModal ref="newPOModalRef">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ $t("Create New PO") }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div> 
                            <label class="form-check-label" for="supplierLookup"> {{ $t("Supplier") }} </label>
                            <ODataLookup :data-object="dsPOSuppliers" id="supplierLookup" class="form-select" readonly
                                :bind="sel=>{ supplierToAdd.ID = sel.ID; supplierToAdd.Name = sel.Company  }"
                                :value="supplierToAdd.Name ?? 'Select Supplier'" >
                                <OColumn field="CompanyNo" width="100"></OColumn>
                                <OColumn field="Company" width="300"></OColumn>
                            </ODataLookup>
                        </div>

                        <div>
                            <label class="form-check-label" for="orgiunitLookup"> {{ $t("OrgUnit") }} </label>
                            <OOrgunitsLookup 
                                textInput readonly
                                :bind="sel=>{ orgunitToAdd.ID = sel.ID; orgunitToAdd.Name = sel.OrgUnit}" 
                                :value="orgunitToAdd.Name ?? 'Select OrgUnit'" 
                                class="form-select" >
                            </OOrgunitsLookup>
                        </div>

                        <div> 
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div v-if="loading">
                            <span class="spinner-border spinner-border-sm"> </span>
                        </div>
                        <div v-if="!loading">
                            <button type="button" class="btn btn-secondary" style="margin-right: 8px;" data-bs-dismiss="modal"> {{ $t("Close") }}</button>
                            <button type="button" class="btn btn-primary" @click=createNewPO() :disabled="supplierToAdd.ID == null || orgunitToAdd.ID == null" > {{ $t("Create New PO") }} </button>
                        </div>
                    </div>
                </div>
            </div>
        </OModal>

        <div style=" min-width: 500px; max-width: 1500px; " class="mt-3" v-if="dsPOPurchaseOrder.current.ID">
        <!-- tabs -->
            <div>
                <h4 class="mt-2 ms-1 mb-1" style="min-height: 29px;" v-if="props.itemId"> <a  :href="`/nt/stock-purchase-orders/stock-purchase-order?ID=${dsPOPurchaseOrder.current.ID}`"> {{ $t("Purchase Order") }} : {{dsPOPurchaseOrder.current.ID}} </a>  </h4>
                <h4 class="mt-2 ms-1 mb-1" style="min-height: 29px;" v-if="!props.itemId"> {{ $t("Purchase Order") }} : {{dsPOPurchaseOrder.current.ID}} </h4>

            </div>

                    <div >
                        <OForm >
                        <fieldset :disabled="props.readOnly">
                            <OToolbar :dataObject="dsPOPurchaseOrder" class="justify-content-between mt-3">
                                <div type="text" style="font-weight: bold;"> {{ $t("Status") }}: {{dsPOPurchaseOrder.current.Status}} </div>

                            </OToolbar>
                            <div class= "border-bottom  ">
                                <div class="row">
                                    <div class="col-6 py-2" >
                                        <label class="form-label" for="supplierLookup"> {{ $t("Supplier") }} </label>
                                        <ODataLookup :data-object="dsPOSuppliers" id="supplierLookup" class="form-select" readonly
                                            :bind="sel=>{ dsPOPurchaseOrder.current.Supplier_ID = sel.ID; dsPOPurchaseOrder.current.Supplier = sel.Company;}"
                                            :value="dsPOPurchaseOrder.current.Supplier ?? 'No supplier'" >
                                            <OColumn field="CompanyNo" width="100"></OColumn>
                                            <OColumn field="Company" width="300"></OColumn>
                                        </ODataLookup>
                                        <div style="padding-left:8px;">
                                            <div type="text" > {{dsPOPurchaseOrder.current.Country}} </div>
                                            <span type="text" > {{dsPOPurchaseOrder.current.Postcode }} - </span>
                                            <span type="text" >  {{dsPOPurchaseOrder.current.Address}} </span>
                                            <div type="text" > {{dsPOPurchaseOrder.current.Location}} </div>
                                        </div>
                                    </div>
                                    <div class="col-6 py-2" >
                                        <label class="form-label" for="orgunitlkp"> {{ $t("Orgunit") }} </label>
                                        <OrgunitsLookup readonly
                                            id="orgunitlkp"
                                            textInput
                                            :bind="onOrgUnitSelected"                                            
                                            class="form-select" 
                                            v-model="dsPOPurchaseOrder.current.OrgUnit"
                                        >
                                        </OrgunitsLookup>   
                                        <div style="padding-top:8px;">
                                            <label class="form-label" for="orgunitlkp"> {{ $t("Delivery Address") }} </label>
                                            <OTextArea class="form-control no-border" v-model="dsPOPurchaseOrder.current.DeliveryAddress" > </OTextArea>
                                        </div>
                                    </div>

                                    <div class="col-6 py-2">
                                        <label class="form-label"> {{ $t("Requested By") }} </label>
                                        <OPersonsLookup v-model = "dsPOPurchaseOrder.current.RequestedBy" class="form-select" readonly
                                            :bind="sel=>{console.log(sel); dsPOPurchaseOrder.current.RequestedBy_ID=sel.ID; dsPOPurchaseOrder.current.RequestedBy = sel.Name}" >
                                        </OPersonsLookup>
                                        
                                    </div>
                                    <div class="col-6 py-2">
                                        <label class="form-label"> {{ $t("Our Reference Person") }} </label>
                                        <OPersonsLookup v-model = "dsPOPurchaseOrder.current.OurReferencePerson" class="form-select" readonly
                                            :bind="sel=>{console.log(sel); dsPOPurchaseOrder.current.OurReferencePerson_ID=sel.ID; dsPOPurchaseOrder.current.OurReferencePerson = sel.Name}" >
                                        </OPersonsLookup>
                                    </div>

                                    <div class="col-6 py-2" v-if="dsPOPurchaseOrder.current.Approved">
                                        <label class="form-label"> {{ $t("Approved") }} </label>
                                        <ODatePicker class="form-control" v-model="dsPOPurchaseOrder.current.Approved" format="Short Date" readonly />

                                    </div>
                                    <div class="col-6 py-2"  v-if="dsPOPurchaseOrder.current.Approved">
                                        <label class="form-label"> {{ $t("Approved By") }} </label>
                                        <input class="form-control" v-model="dsPOPurchaseOrder.current.ApprovedBy" readonly />
                                    </div>
                                    <div class="col-6" v-if="dsPOPurchaseOrder.current.Ordered">
                                        <label class="form-label"> {{ $t("Ordered") }} </label>
                                        <ODatePicker class="form-control" v-model="dsPOPurchaseOrder.current.Ordered" format="Short Date" readonly />
                                    </div>
                                    <div class="col-6" v-if="dsPOPurchaseOrder.current.Ordered">
                                        <label class="form-label"> {{ $t("Ordered By") }} </label>
                                        <input class="form-control" v-model="dsPOPurchaseOrder.current.OrderedBy" readonly />
                                    </div>
                                    <div class="col-6 py-2" v-if="dsPOPurchaseOrder.current.Closed">
                                        <label class="form-label"> {{ $t("Closed") }} </label>
                                        <ODatePicker class="form-control" v-model="dsPOPurchaseOrder.current.Closed" format="Short Date" readonly />
                                    </div>
                                    <div class="col-6 py-2"  v-if="dsPOPurchaseOrder.current.Closed">
                                        <label class="form-label"> {{ $t("Closed By") }} </label>
                                        <input class="form-control" v-model="dsPOPurchaseOrder.current.ClosedBy" readonly />
                                    </div>
                                    <div class="col-3 py-2" style="margin-bottom: 12px;">
                                        <label class="form-label" for="currencyLookup"> {{ $t("Currency") }} </label>
                                        <ODataLookup :data-object="dsPOCurrencies" id="currencyLookup" class="form-select" readonly
                                            :bind="sel=>{ dsPOPurchaseOrder.current.Currency = sel.Currency; setExchRates(); }"
                                            :value="dsPOPurchaseOrder.current.Currency ?? 'Select Currency'" >
                                            <OColumn field="Currency" width="150"></OColumn>
                                            <OColumn field="ExchangeRate" width="80"></OColumn>
                                        </ODataLookup>
                                    </div> 
                                    <div class="col-3 py-2">
                                        <label class="form-label"> {{ $t("Delivery Date") }} </label>
                                        <ODatePicker class="form-control" v-model="dsPOPurchaseOrder.current.DeliveryDate" :showInputIcon="true" format="Short Date"  />
                                    </div>
                                    <div class="col-3 py-2">
                                        <label class="form-label"> {{ $t("Payment Due Date") }} </label>
                                        <ODatePicker class="form-control" v-model="dsPOPurchaseOrder.current.PaymentDueDate" :showInputIcon="true" format="Short Date"  />
                                    </div>
                                </div>
                            </div>
                            <div> 
                                <label class="form-label"> {{ $t("Comments") }} </label>
                                <OTextArea class="form-control no-border" v-model="dsPOPurchaseOrder.current.Comments" > </OTextArea>
                            </div>
                            <div class="m-2">
                                <div v-if="dsPOPurchaseOrder.current.Status"> 
                                        
                                    
                                    <div v-if='dsPOPurchaseOrder.current.Status == "New"' > 
                                        <a class="" target="report" :href="`/file/download/reporting/0/arpt_Stock_PORequest/PurchaseOrderRequest_${dsPOPurchaseOrder.current.ID}.pdf?Filter=ID=${dsPOPurchaseOrder.current.ID}`">{{ $t('Export As PDF') }}</a>
                                        <button class="btn btn-primary btn-sm ms-1" @click="sendForApproval" style="widht:auto;float:right;"> {{ $t("Send For Approval") }} <i class="bi bi-send"></i> </button>
                                        <button class="btn btn-secondary btn-sm" @click="cancelPurchaseOrder" style="widht:auto;float:right;"> {{ $t("Cancel Order") }} <i class="bi bi-x-octagon"></i> </button>
                                    </div>
                                    <div v-if='dsPOPurchaseOrder.current.Status == "Pending Approval"'> 
                                        <a class="" target="report" :href="`/file/download/reporting/0/arpt_Stock_PORequest/PurchaseOrderRequest_${dsPOPurchaseOrder.current.ID}.pdf?Filter=ID=${dsPOPurchaseOrder.current.ID}`">{{ $t('Export As PDF') }}</a>
                                        <button class="btn btn-primary btn-sm ms-1" @click="approvePurchaseOrder" style="widht:auto;float:right;"> {{ $t("Approve") }} <i class="bi bi-send"></i> </button>
                                        <button class="btn btn-secondary btn-sm" @click="rejectPurchaseOrder" style="widht:auto;float:right;"> {{ $t("Reject") }} <i class="bi bi-x-octagon"></i> </button>
                                    </div>
                                    <div v-if='dsPOPurchaseOrder.current.Status == "Approved"'> 
                                        <a class="" target="report" :href="`/file/download/reporting/0/arpt_Stock_PO/PurchaseOrder_${dsPOPurchaseOrder.current.ID}.pdf?Filter=ID=${dsPOPurchaseOrder.current.ID}`">{{ $t('Export As PDF') }}</a>
                                        <button class="btn btn-primary btn-sm ms-1" @click="setAsOrdered" style="widht:auto;float:right;"> {{ $t("Set as Ordered") }} <i class="bi bi-send"></i> </button>
                                        <button class="btn btn-secondary btn-sm" @click="cancelPurchaseOrder" style="widht:auto;float:right;"> {{ $t("Cancel Order") }} <i class="bi bi-x-octagon"></i> </button>
                                    </div>
                                    <div v-if='dsPOPurchaseOrder.current.Status == "Ordered" || dsPOPurchaseOrder.current.Status == "Partially received" || dsPOPurchaseOrder.current.Status == "Received"'> 
                                        <a class="" target="report" :href="`/file/download/reporting/0/arpt_Stock_PO/PurchaseOrder_${dsPOPurchaseOrder.current.ID}.pdf?Filter=ID=${dsPOPurchaseOrder.current.ID}`">{{ $t('Export As PDF') }}</a>
                                        <button class="btn btn-primary btn-sm" @click="openReceipts" style="widht:auto;float:right;"> {{ $t("Open Receipts") }}  </button> 
                                    </div>                          
                                </div>
                            </div>
                        </fieldset>
                        </OForm>
                    </div>
                    <OTabs class="mt-1">
                        <OTab title="Purchase Items" active>
                    <div > 
                        <fieldset :disabled="props.readOnly">
                            <ODataGrid :data-object="dsPOPurchaseOrderItems" hideGridMenu style="min-height:250px">

                                    
                                <OColumn field="ProductNo" width="150" sortable :editable="!props.readOnly" v-slot:editor="{modelValue: row}">                
                                    <ODataLookup 
                                        :data-object="dsPOProducts" 
                                        :bind="sel=>{row.Product_ID = sel.ID; 
                                                    row.Supplier_ID = sel.Supplier_ID;
                                                    row.ProductNo = sel.ProductNo; 
                                                    row.Product = sel.Name; 
                                                    row.Unit_ID = sel.Unit_ID; 
                                                    row.Unit = sel.Unit; 
                                                    row.ExchangeRate = dsPOExchRates.current.Rate;
                                                    row.EstimatedDeliveryDate = dsPOPurchaseOrder.current.DeliveryDate;
                                                    row.PricePerUnitWithoutDiscount = sel.Price; 
                                                    row.QtyOrdered == null ? row.QtyOrdered = 1 : row.QtyOrdered = row.QtyOrdered; 
                                                    console.log(sel.Supplier_ID);}" 
                                        v-model="row.ProductNo" readonly>
                                            <OColumn field="ProductNo" width="100"></OColumn>
                                            <OColumn field="Name" width="350"></OColumn>
                                            <OColumn field="Price" width="80"></OColumn>
                                    </ODataLookup>
                                </OColumn>
                                <OColumn field="Product" width="300" :editable="!props.readOnly"> </OColumn>
                                <OColumn field="PricePerUnitWithoutDiscount" class="text-end" width="100" :editable="!props.readOnly"></OColumn>
                                <OColumn hide field="PricePerUnit" class="text-end" width="100"></OColumn>
                                <OColumn field="QtyOrdered" class="text-end" :editable="!props.readOnly" width="80"></OColumn>            
                                <OColumn field="Unit" width="60" :editable="!props.readOnly" v-slot:editor="{modelValue: row}">        
                                    <ODataLookup 
                                        :data-object="dsPOUnits" 
                                        :bind="sel=>{row.Unit_ID = sel.ID;  row.Unit = sel.Name; }" 
                                        v-model="row.Unit" readonly>
                                            <OColumn field="Name" width="80"></OColumn>
                                            <OColumn field="description" width="200"></OColumn>
                                    </ODataLookup>
                                </OColumn>
                                <OColumn field="Discount" class="text-end" format="%" width="100" :editable="!props.readOnly"></OColumn>
                                <OColumn field="TotalPrice" class="text-end" width="100"></OColumn>
                                <OColumn field="ExchangeRate" class="text-end" width="100"></OColumn>
                                <OColumn field="EstimatedDeliveryDate" width="100" format="Short Date" :editable="!props.readOnly"></OColumn>
                                <OColumn field="ActualDeliveryDate" width="100"></OColumn>
                            </ODataGrid> 
                        </fieldset>
                    </div>
                    </OTab>
                    <OTab title="Files" > 
                        
                        <fieldset :disabled="props.readOnly">
                            <ODataGrid :data-object="dsPOPurchaseOrderFiles" filter-row hideGridMenu>
                                <template #cardheader>
                                    <div class="p-2">
                                        <FileUpload class="ms-2 btn btn-sm btn-primary" :dataObject="dsPOPurchaseOrderFiles" @uploaded="completeUpload" multiple="multiple"><i class="bi bi-cloud-arrow-up"></i> {{ $t("Upload file") }}</FileUpload>
                                    </div>
                                </template>
                                <OColumn :width="280" sortable menu hide field="PrimKey"></OColumn>
                                <OColumn :width="60"  sortable menu hide field="ID"></OColumn>
                                <OColumn :width="130" sortable menu hide field="Created"></OColumn>
                                <OColumn :width="130" sortable menu hide field="Updated"></OColumn>
                                <OColumn field="FileName" width="600" :cellrendererparams="`/api/file/download/atbv_Stock_PurchaseOrdersFiles/{{PrimKey}}/{{FileName}}`" cellrenderer="OLink"/>
                            </ODataGrid>
                        </fieldset> 
                    </OTab>
            </OTabs>
        </div>
    </div>
</template>

<script setup>
    import { getOrCreateDataObject, getOrCreateProcedure } from 'o365.vue.ts';
    import { ref, defineProps, onMounted } from 'vue';
    import OPersonsLookup from 'o365.vue.components.PersonsLookup.vue'
    import alert from 'o365.controls.alert.ts';
    import OToolbar from "o365.vue.components.Toolbar.vue";
    import OrgunitsLookup from 'o365.vue.components.OrgUnitsLookup.vue'
    import OModal from 'o365.vue.components.Modal.vue';
    import OOrgunitsLookup from "o365.vue.components.OrgUnitsLookup.vue";
    import FileUpload from "o365.vue.components.FileUploadButton.vue";
    
    

    const createPO = ref(false);
    const loading = ref(false);
    const initializing = ref(true);
    const newPOModalRef = ref(null);
    const supplierToAdd = ref({ Name: null, ID: null });
    const orgunitToAdd = ref({ Name: null, ID: null });
    const personID = parseInt(document.querySelector("meta[name='o365-person-id']")?.content)
    const fieldsetRef = ref(null);
    
    const props = defineProps({
        itemId: Number,
        stepId: Number,
        poid: Number,
        readOnly: Boolean
    });


    const procNewPurchaseOrder = getOrCreateProcedure({ id:"procNewPurchaseOrder", procedureName:"astp_Stock_PuchaseOrderNew" });
    

    const dsPOPurchaseOrder = getOrCreateDataObject(
    {
        "id": "dsPOPurchaseOrder",
        "viewName": "aviw_Stock_PurchaseOrders",
        "distinctRows": false,
        "uniqueTable": "atbl_Stock_PurchaseOrders",
        "allowUpdate": true,
        "allowInsert": false,
        "allowDelete": false,
        "appendData": false,
        "selectFirstRowOnLoad": true,
        "fields": [
            {
                "name": "ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "Comments",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "Approved",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "Status",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "AccessIdPath",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "OrgUnitIdPath",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "OrgUnit",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "RequestedBy_ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "RequestedBy",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "Supplier",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "DeliveryAddress",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "Address",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "Postcode",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "Location",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "Country",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "OurReferencePerson",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "ApprovedBy",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "Currency",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "Supplier_ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "OrgUnit_ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "Ordered",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": "datetime",
                "aggregate": null
            },
            {
                "name": "OrderedBy",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "ClosedBy",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "Closed",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": "datetime",
                "aggregate": null
            },
            {
                "name": "OurReferencePerson_ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "DeliveryDate",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": "datetime",
                "aggregate": null
            },
            {
                "name": "PaymentDueDate",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": "datetime",
                "aggregate": null
            },
            {
                "name": "Workflow_ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            }
            
        ],
        "masterDataObject_ID": null,
        "masterDetailDefinition": [],
        "clientSideHandler": false,
        "maxRecords": 10,
        "dynamicLoading": false,
        "whereClause": null,
        "filterString": null,
        "disableAutoLoad": false,
        "optimisticLocking": false
    });



    const dsPOPurchaseOrderItems = getOrCreateDataObject({
        "id": "dsPOPurchaseOrderItems",
        "viewName": "aviw_Stock_PurchaseOrdersItems",
        "distinctRows": false,
        "uniqueTable": "atbl_Stock_PurchaseOrdersItems",
        "allowUpdate":  true,
        "allowInsert":  true,
        "allowDelete":  true,
        "appendData": false,
        "selectFirstRowOnLoad": true,
        "fields": [
            {
                "name": "ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "PurchaseOrder_ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "Product_ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "Comments",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "Product",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "QtyOrdered",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": "number",
                "aggregate": null
            },
            {
                "name": "Unit",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "Unit_ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "AllowMetaChanges",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "SupplierArticleNo",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "ProductNo",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "PricePerUnitWithoutDiscount",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": "number",
                "aggregate": null
            },
            {
                "name": "TotalPrice",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": "number",
                "aggregate": null
            },
            {
                "name": "Discount",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": "number",
                "aggregate": null
            },
            {
                "name": "PricePerUnit",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": "number",
                "aggregate": null
            },
            {
                "name": "Supplier_ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "ExchangeRate",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": "number",
                "aggregate": null
            },
            {
                "name": "ActualDeliveryDate",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": "datetime",
                "aggregate": null
            },
            {
                "name": "EstimatedDeliveryDate",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": "datetime",
                "aggregate": null
            },
        ],
        "masterDataObject_ID": "dsPOPurchaseOrder",
        "masterDetailDefinition": [
            {
                "detailField": "PurchaseOrder_ID",
                "operator": "equals",
                "masterField": "ID"
            }
        ],
        "clientSideHandler": false,
        "maxRecords": -1,
        "dynamicLoading": false,
        "whereClause": "",
        "filterString": null,
        "disableAutoLoad": false,
        "optimisticLocking": false
    });

    function sendForApproval(){
    console.log(dsPOPurchaseOrder.current.DeliveryLocation_ID);
        dsPOPurchaseOrder.current.Status = "Pending Approval"; 
        dsPOPurchaseOrder.save().then(() => dsPOPurchaseOrder.load());
    }

    function createNewPO(){
        if(loading.value) { return; }
        loading.value = true;
        if(supplierToAdd.value.ID == null || orgunitToAdd.value.ID == null) 
        { 
            loading.value = false;
            alert( $t( "Please select Supplier And OrgUnit"), "warning", {
                autohide : true ,
                delay    : 2000 ,
            });

            return; 
        }
        procNewPurchaseOrder.execute({
            OrgUnit_ID : orgunitToAdd.value.ID,
            Supplier_ID : supplierToAdd.value.ID,
            OurReferencePerson_ID: personID,
            RequestedBy_ID: personID,
            Workflow_ID: props.itemId })
        .then((pData)=>{
            loading.value = false;
            dsPOPurchaseOrder.load().then(() => { dsPOProducts.recordSource.whereClause = "'" + dsPOPurchaseOrder.current.OrgUnitIdPath + "' LIKE '%' + OrgUnitIdPath + '%' "; });;
            createPO.value = false;
            newPOModalRef.value.hide();
            // window.location.href="/nt/stock-purchase-orders/stock-purchase-order?ID=" + pData.Table[0].ID;
        }).catch((pErr)=>{
            if(pErr){
                loading.value = false;
                dsPOPurchaseOrder.load();
                //alert('Something went wrong' + pErr, ToastType.Info);
            }
        });
    }

    function approvePurchaseOrder(){
        var currentDate = new Date().getUTCDate;
        dsPOPurchaseOrder.current.Status = 'Approved';
        dsPOPurchaseOrder.current.Approved = currentDate;
        dsPOPurchaseOrder.current.ApprovedBy_ID = parseInt(document.querySelector("meta[name='o365-person-id']")?.content);
        dsPOPurchaseOrder.save().then(() => dsPOPurchaseOrder.load());
    }
    
    function rejectPurchaseOrder(){
        dsPOPurchaseOrder.current.Status = 'Not Approved';
        dsPOPurchaseOrder.save().then(() => dsPOPurchaseOrder.load());
    }

    function setAsOrdered(){
        dsPOPurchaseOrder.current.Status = 'Ordered';
        dsPOPurchaseOrder.save().then(() => dsPOPurchaseOrder.load());
    }

    function cancelPurchaseOrder(){
        dsPOPurchaseOrder.current.Status = 'Cancelled';
        dsPOPurchaseOrder.save().then(() => dsPOPurchaseOrder.load());
    }

    function openReceipts(){
        window.location.href="/nt/stock-goods-receipts";
    }
    

    async function onOrgUnitSelected(row) {
    debugger;
        dsPOPurchaseOrder.current.OrgUnit_ID = row.ID;
        dsPOPurchaseOrder.current.OrgUnit = row.OrgUnit;
    }  

    function setExchRates(){
        debugger;
        dsPOExchRates.recordSource.whereClause = "Currency = '" + dsPOPurchaseOrder.current.Currency + "' AND '" + dsPOPurchaseOrder.current.OrgUnitIdPath + "' LIKE '%' + IdPath + '%' ";
        dsPOExchRates.load().then(() => console.log(dsPOExchRates.current.Rate) ); 
        console.log('123');
    }

    function completeUpload(){
        dsPOPurchaseOrderFiles.load();
    }




const dsPOProducts = getOrCreateDataObject(
{
    "id": "dsPOProducts",
    "viewName": "aviw_Stock_ProductsLookup",
    "distinctRows": true,
    "uniqueTable": null,
    "allowUpdate": false,
    "allowInsert": false,
    "allowDelete": false,
    "appendData": false,
    "selectFirstRowOnLoad": true,
    "fields": [
        {
            "name": "ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Name",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Description",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Price",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Currency",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "ProductGroup",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "ProductGroupIdPath",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "SummaryImagePrimKey",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Unit_ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "AllowMetaChanges",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "SupplierArticleNo",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Unit",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "HasSubNodes",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "ProductNo",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Supplier_ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "OrgUnitIdPath",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        
    ],
    "masterDataObject_ID": "dsPOPurchaseOrder",
    "masterDetailDefinition": [
        {
            "detailField": "Supplier_ID",
            "operator": "equals",
            "masterField": "Supplier_ID"
        }
    ],
    "clientSideHandler": false,
    "maxRecords": -1,
    "dynamicLoading": false,
    "whereClause":  null,
    "filterString": null,
    "disableAutoLoad": false,
    "optimisticLocking": false
});

    dsPOProducts.fields.loadAlways = ['Unit_ID', 'Unit'];
    onMounted( () => {
    
        if(!props.poid) {
        debugger;

            dsPOPurchaseOrder.recordSource.whereClause = "Workflow_ID = " + props.itemId;
            console.log(dsWorkflow.current.OrgUnit_ID);
            console.log(props.stepId);
            orgunitToAdd.value.ID = dsWorkflow.current.OrgUnit_ID;
            orgunitToAdd.value.Name = dsWorkflow.current.OrgUnit;
            dsPOPurchaseOrder.load().then(() => {  
                /*
                if(!dsPOPurchaseOrder.current.ID)
                    { 
                        debugger;
                        createPO.value = true; 
                    } else 
                    { 
                        debugger;
                        createPO.value = false; 
                    }
                */ 
                    //dsPOProducts.recordSource.whereClause = "AccessOrgUnit_ID = " + dsPOPurchaseOrder.current.OrgUnit_ID;
                    dsPOProducts.recordSource.whereClause = "'" + dsPOPurchaseOrder.current.OrgUnitIdPath + "' LIKE '%' + OrgUnitIdPath + '%' ";
                    initializing.value = false; 
                    console.log(dsPOProducts.recordSource.whereClause);
                    dsPOExchRates.recordSource.whereClause = "Currency = '" + dsPOPurchaseOrder.current.Currency + "' AND '" + dsPOPurchaseOrder.current.OrgUnitIdPath + "' LIKE '%' + IdPath + '%' ";
                    dsPOExchRates.load();

                });

        } else {
        debugger;
            dsPOPurchaseOrder.recordSource.whereClause = "ID = " + props.poid;
        


        dsPOPurchaseOrder.load().then(() => {  
                //dsPOProducts.recordSource.whereClause = "AccessOrgUnit_ID = " + dsPOPurchaseOrder.current.OrgUnit_ID;
                dsPOProducts.recordSource.whereClause = "'" + dsPOPurchaseOrder.current.OrgUnitIdPath + "' LIKE '%' + OrgUnitIdPath + '%' ";
                initializing.value = false; 
                console.log(dsPOProducts.recordSource.whereClause);
                dsPOExchRates.recordSource.whereClause = "Currency = '" + dsPOPurchaseOrder.current.Currency + "' AND '" + dsPOPurchaseOrder.current.OrgUnitIdPath + "' LIKE '%' + IdPath + '%' ";
                dsPOExchRates.load();

            });
        }

    if(props.readOnly)
    {
        console.log(fieldsetRef.value);
    } 

    });
//console.log(dsPOPurchaseOrder.current);
//dsPOProducts.recordSource.whereClause = "'" + dsPOPurchaseOrder.current.OrgUnitIdPath + "' LIKE '%' + OrgUnitIdPath + '%' ";


const dsPOCurrencies = getOrCreateDataObject(
{
    "id": "dsPOCurrencies",
    "viewName": "stbv_System_Currencies",
    "distinctRows": false,
    "uniqueTable": null,
    "allowUpdate": false,
    "allowInsert": false,
    "allowDelete": false,
    "appendData": false,
    "selectFirstRowOnLoad": true,
    "fields": [
        {
            "name": "ID",
            "sortOrder": 1,
            "sortDirection": "asc",
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        },
        {
            "name": "Currency",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        },
        {
            "name": "SortOrder",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        },
        {
            "name": "ExchangeRate",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        }
    ],
    "masterDataObject_ID": null,
    "masterDetailDefinition": [],
    "clientSideHandler": false,
    "maxRecords": -1,
    "dynamicLoading": false, 
    "whereClause": null,
    "filterString": null
});

const dsPOSuppliers = getOrCreateDataObject(
{
    "id": "dsPOSuppliers",
    "viewName": "aviw_Stock_Suppliers",
    "distinctRows": false,
    "uniqueTable": null,
    "allowUpdate": false,
    "allowInsert": false,
    "allowDelete": false,
    "appendData": false,
    "selectFirstRowOnLoad": true,
    "fields": [
        {
            "name": "ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        },
        {
            "name": "Company",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        },
        {
            "name": "CompanyNo",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        },
        {
            "name": "ContactPerson",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        },
        {
            "name": "ContactEmail",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        },
        {
            "name": "ContactPhoneNumber",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        },
        {
            "name": "Address",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        },
        {
            "name": "Postcode",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        }
    ],
    "masterDataObject_ID": null,
    "masterDetailDefinition": [],
    "clientSideHandler": false,
    "maxRecords": -1,
    "dynamicLoading": false,
    "whereClause": null,
    "filterString": null
});

const dsPOUnits = getOrCreateDataObject(
{
    "id": "dsPOUnits",
    "viewName": "sviw_System_Units",
    "distinctRows": false,
    "uniqueTable": null,
    "allowUpdate": false,
    "allowInsert": false,
    "allowDelete": false,
    "appendData": false,
    "selectFirstRowOnLoad": true,
    "fields": [
        {
            "name": "Name",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        },
        {
            "name": "ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        },
        {
            "name": "Description",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        }
    ],
    "masterDataObject_ID": null,
    "masterDetailDefinition": [],
    "clientSideHandler": false,
    "maxRecords": -1,
    "dynamicLoading": false,
    "whereClause": null,
    "filterString": null
});

const dsPOExchRates = getOrCreateDataObject(
{
    "id": "dsPOExchRates",
    "viewName": "aviw_Stock_ExchangeRates",
    "distinctRows": false,
    "uniqueTable": null,
    "allowUpdate": false,
    "allowInsert": false,
    "allowDelete": false,
    "appendData": false,
    "selectFirstRowOnLoad": true,
    "fields": [
        {
            "name": "Rate",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        },
        {
            "name": "IdPath",
            "sortOrder": 1,
            "sortDirection": "asc",
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        },
        {
            "name": "Currency",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        },
        {
            "name": "OrgUnit_ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        }
    ],
    "masterDataObject_ID": null,
    "masterDetailDefinition": [],
    "clientSideHandler": false,
    "maxRecords": 1,
    "dynamicLoading": false,
    "whereClause": null,
    "filterString": null
});


const dsPOPurchaseOrderFiles = getOrCreateDataObject(
{
    "id": "dsPOPurchaseOrderFiles",
    "viewName": "atbv_Stock_PurchaseOrdersFiles",
    "distinctRows": false,
    "uniqueTable": null,
    "allowUpdate": props.readOnly ? false : true,
    "allowInsert": props.readOnly ? false : true,
    "allowDelete": props.readOnly ? false : true,
    "appendData": false,
    "selectFirstRowOnLoad": true,
    "fields": [
        {
            "name": "PrimKey",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        },
        {
            "name": "ID",
            "sortOrder": 1,
            "sortDirection": "asc",
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        },
        {
            "name": "FileName",
            "type": "string",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        },
        {
            "name": "FileSize",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        },
        {
            "name": "FileUpdated",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        },
        {
            "name": "FileRef",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        },
        {
            "name": "Extension",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        },
        {
            "name": "PurchaseOrder_ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        }
    ],
    "masterDataObject_ID": "dsPOPurchaseOrder",
    "masterDetailDefinition": [
        {
            "detailField": "PurchaseOrder_ID",
            "operator": "equals",
            "masterField": "ID"
        }
    ],
    "clientSideHandler": false,
    "maxRecords": 1,
    "dynamicLoading": false,
    "whereClause": null,
    "filterString": null
});




</script>